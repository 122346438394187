<template>
  <div class="index">
    <van-nav-bar
        left-text="联系我们"
        left-arrow
        @click-left="onClickLeft"
    />
    <div class="content">
      <div class="kf-card">
        <div class="name"><span
            class="bold">客服助手</span></div>
        <img v-if="userDatalt" :src="userDatalt"
             class="qr">
        <p class="tips"><img src="../../assets/images/mousehard.png"> <span>扫描二维码添加客服</span>
        </p></div>
    </div>
  </div>
</template>

<script>
import {userCenter} from "../../api/homePage";

export default {
  data() {
    return {
      // 文字
      message: '',
      // tbas切换
      tabsChose: 0,
      userDatalt: ''
    }
  },
  watch: {},
  created() {
    this.userDetails()
  },
  methods: {
    onClickLeft(){
      this.$router.back()
    },
    userDetails() {
      userCenter().then(res => {
        this.userDatalt = res.data.aboutUs
      })
    }
  }
}
</script>

<style lang="less" scoped>
/deep/.van-nav-bar__content{
  background: #2b63cd;
  color: #fff;
}
/deep/.van-nav-bar .van-icon{
  color: #fff;
}
/deep/.van-nav-bar__text{
  color: #fff;
}
/deep/.van-nav-bar__title{
  color: #fff;
}
.index {
  font-size: 14px;
  height: 100%;
  background: #F1f2f5;

  p {
    margin: 0 !important;
  }

  .fs14 {
    font-size: 14px;
  }

  .fs16 {
    font-size: 16px;
  }
  .content{
    padding: 22px 23px;
    height: 93%;
    .kf-card{
      background: #fff;
      border-radius: 4px;
      padding: 24px 30px 50px;
      height: 398px;
      .name{
        display: flex;
        -webkit-box-align: center;
        align-items: center;
        .bold{
          font-size: 17px;
          line-height: 17px;
          color: #000;
          margin-left: 14px;
        }
        img{
          width: 46px;
          height: 50px;
        }
      }
      .qr{
        width: 229px;
        height: 229px;
        margin: 10px auto 0;
        display: block;
      }
      .tips{
        margin-top: 15px;
        text-align: center;
        font-size: 15px;
        line-height: 22.75px;
        color: #444;
        margin-top: 17px !important;
        img{
          vertical-align: middle;
          width: 16px;
          height: 20px;
          margin-right: 9px;
        }
      }
    }
  }

}
</style>
