<template>
  <div class="index">
    <van-nav-bar
        left-text="意见反馈"
        left-arrow
        @click-left="onClickLeft"
        @click-right="onClickRight"
    />
    <div class="content">
      <h5 style="    color: #444;
    font-size: 14px;
    font-weight: 500;">内容描述</h5>
      <van-field
          style="    background: #f6f6f6;"
          v-model="message"
          rows="2"
          :autosize="{ maxHeight: 300, minHeight: 170 }"
          type="textarea"
          maxlength="300"
          placeholder="在这里说说你的宝贵意见"
          show-word-limit
      />
      <van-uploader style="margin-top: 10px;" :after-read="afterRead" />

      <van-button type="info" block style="width: 100%; margin-top: 30px;">提交</van-button>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      // 文字
      message: '',
      // tbas切换
      tabsChose: 0
    }
  },
  watch: {},
  methods: {}
}
</script>

<style lang="less" scoped>
/deep/.van-nav-bar__content{
  background: #2b63cd;
  color: #fff;
}
/deep/.van-nav-bar .van-icon{
  color: #fff;
}
/deep/.van-nav-bar__text{
  color: #fff;
}
/deep/.van-nav-bar__title{
  color: #fff;
}
.index {
  font-size: 14px;
  height: 100%;
  background: #fff;

  p {
    margin: 0 !important;
  }

  .fs14 {
    font-size: 14px;
  }

  .fs16 {
    font-size: 16px;
  }
  .content{
    padding: 22px 23px;
    height: 93%;
    .kf-card{
      background: #fff;
      border-radius: 4px;
      padding: 24px 30px 50px;
      height: 398px;
      .name{
        display: flex;
        -webkit-box-align: center;
        align-items: center;
        .bold{
          font-size: 17px;
          line-height: 17px;
          color: #000;
          margin-left: 14px;
        }
        img{
          width: 46px;
          height: 50px;
        }
      }
      .qr{
        width: 229px;
        height: 229px;
        margin: 10px auto 0;
        display: block;
      }
      .tips{
        margin-top: 15px;
        text-align: center;
        font-size: 15px;
        line-height: 22.75px;
        color: #444;
        margin-top: 17px !important;
        img{
          vertical-align: middle;
          width: 16px;
          height: 20px;
          margin-right: 9px;
        }
      }
    }
  }

}
</style>
